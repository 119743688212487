<template>
  <button
    v-if="type === 'next'"
    class="inline-flex between-xs w-100 px25 py20 pr15 serif cl-accent align-left fs-medium"
    type="button"
    @click.stop="next()"
    :aria-label="$t('Show subcategories')"
    data-testid="categoryButton"
  >
    {{ name }}
    <i class="icon-rozwi left" />
  </button>
  <button
    v-else
    class="inline-flex p15 between-xs fs-medium back-btn"
    type="button"
    @click.stop="back()"
    :aria-label="$t('Back')"
  >
    <i class="icon-rozwi right" />
    {{ name }}
  </button>
</template>
<script>
import { mapState } from 'vuex'
import config from 'config'
export default {
  name: 'SubBtn',
  props: {
    id: {
      type: null,
      default: ''
    },
    type: {
      type: String,
      default: 'next'
    },
    name: {
      type: String,
      default: ''
    },
    isCategory: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      submenu: state => state.ui.submenu
    })
  },
  methods: {
    next () {
      if (config.entities.category.categoriesDynamicPrefetch && this.isCategory) this.$store.dispatch('category-next/fetchMenuCategories', { parent: this.id })
      this.$store.commit('ui/setSubmenu', {
        id: this.id,
        depth: ++this.submenu.depth
      })
    },
    back () {
      this.$store.commit('ui/setSubmenu', {
        depth: --this.submenu.depth
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gray: color(silver);
$color-black: color(matterhorn);

button {
  i {
    color: $color-gray;
    font-size: 28px;
    line-height: 24px;
  }
  i.left {
    transform: rotate(270deg);
  }
  i.right {
    transform: rotate(90deg);
  }
  &:hover,
  &:focus {
    i {
      color: $color-black;
    }
  }
}
.back-btn {
  vertical-align: middle;
  line-height: 26px;
}
</style>
