<template>
  <div class="sidebar-menu fixed mw-100">
    <div v-if="!submenu.depth && componentLoaded"
         class="row brdr-bottom-1 brdr-cl-secondary bg-cl-primary py15 search-container"
    >
      <search-panel-mobile />
    </div>

    <div class="row brdr-bottom-1 brdr-cl-bg-matterhorn">
      <div
        v-if="submenu.depth"
        class="col-xs-12 bg-cl-main-smoke"
      >
        <sub-btn type="back" :name="getPrevName ? getPrevName : $t('Back')" class="bg-cl-transparent brdr-none" />
      </div>
      <div class="col-xs bg-cl-main-smoke row py15">
        <div v-if="!submenu.depth" class="">
          <span class="pl25 cl-main">{{ $t("You're here") }}: </span>
          <breadcrumbs class="pl25 align-left lh20 fs-medium" :sidebar-menu="true" />
        </div>
      </div>
    </div>
    <div class="sidebar-menu__container row" ref="container">
      <div class="col-xs-12 h4 serif">
        <ul class="p0 m0 relative sidebar-menu__list" :style="mainListStyles">
          <li
            @click="closeMenu"
            class="brdr-bottom-1 brdr-cl-matterhorn bg-cl-primary cl-black"
          >
            <router-link
              class="block px25 py20 cl-black no-underline fs-medium"
              :to="localizedRoute('/')"
              exact
            >
              {{ $t('Home') }}
            </router-link>
          </li>
          <!-- Categories -->
          <li
            class=" bg-cl-primary cl-black flex"
            :key="id + 'sidebar'"
            @click="closeMenu"
            v-for="id in mainCategoryMenu"
          >
            <div
              v-if="isCurrentMenuShowed && (menuCategoriesById[id] && menuCategoriesById[id].length)"
              class="subcategory-item brdr-bottom-1 brdr-cl-matterhorn"
            >
              <sub-btn
                v-if="menuCategoriesById[id][0].children_count > 0"
                class="bg-cl-transparent brdr-none fs-medium"
                :id="menuCategoriesById[id][0].id"
                :name="menuCategoriesById[id][0].name"
              />

              <router-link
                v-else
                class="px25 py20 cl-black no-underline col-xs fs-medium"
                :to="categoryLink(menuCategoriesById[id][0])"
              >
                {{ menuCategoriesById[id][0].name }} <!-- Direct link to category -->
              </router-link>
            </div>
            <!-- subcategory -->
            <sub-category
              v-if="menuCategoriesById[id] && menuCategoriesById[id].length"
              :category-links="menuCategoriesById[id][0].children_data"
              :id="menuCategoriesById[id][0].id"
              :parent-slug="menuCategoriesById[id][0].slug"
              :parent-path="menuCategoriesById[id][0].url_path"
            />
          </li>
          <!-- Categories END -->
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="sale block px25 py20 brdr-bottom-1 brdr-cl-matterhorn no-underline fs-medium"
              :to="localizedRoute('/sklep?sale=true&sort=view_count%3Adesc')"
              exact
            >
              {{ $t('Sale') }}
            </router-link>
          </li>
          <li
            v-if="isCurrentMenuShowed"
            @click="closeMenu"
            class="bg-cl-primary"
          >
            <router-link
              class="bestsellery block px25 py20 brdr-bottom-1 brdr-cl-matterhorn no-underline fs-medium"
              :to="localizedRoute('/sklep?bestsellery=true')"
              exact
            >
              Bestsellery
            </router-link>
          </li>
          <li
            @click="login"
            class="bg-cl-main-smoke cl-black flex"
          >
            <sub-btn
              v-if="currentUser"
              id="my-account-links"
              :is-category="false"
              :name="$t('My account')"
              class="bg-cl-transparent brdr-none fs-medium brdr-bottom-1 brdr-cl-matterhorn"
            />
            <sub-category
              v-if="currentUser"
              :my-account-links="myAccountLinks"
              id="my-account-links"
              @click.native="closeMenu"
            />
            <a
              v-if="!currentUser && isCurrentMenuShowed"
              href="#"
              @click.prevent="closeMenu"
              class="block w-100 px25 py20 cl-black no-underline fs-medium brdr-bottom-1 brdr-cl-matterhorn"
            >
              {{ $t('My account') }}
            </a>
          </li>
          <li
            class=" bg-cl-main-smoke cl-black flex"
            @click="closeMenu"
          >
            <div
              v-if="isCurrentMenuShowed"
              class="subcategory-item brdr-bottom-1 brdr-cl-matterhorn"
            >
              <sub-btn
                class="bg-cl-transparent brdr-none fs-medium"
                :id="'o-nas-links'"
                :is-category="false"
                :name="'O nas'"
              />
            </div>
            <sub-category
              :custom-links="oNasLinks"
              :id="'o-nas-links'"
              @click.native="closeMenu"
            />
          </li>
          <li
            class=" bg-cl-main-smoke cl-black flex save-area-overlay"
            @click="closeMenu"
          >
            <div
              v-if="isCurrentMenuShowed"
              class="subcategory-item brdr-bottom-1 brdr-cl-matterhorn"
            >
              <sub-btn
                class="bg-cl-transparent brdr-none fs-medium"
                :id="'obsluga-klienta-links'"
                :is-category="false"
                :name="'Obsługa klienta'"
              />
            </div>
            <sub-category
              :custom-links="clientServiceLinks"
              :id="'obsluga-klienta-links'"
              @click.native="closeMenu"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'config'
import { mapState, mapGetters } from 'vuex'
import i18n from '@vue-storefront/i18n'
import SidebarMenu from '@vue-storefront/core/compatibility/components/blocks/SidebarMenu/SidebarMenu'
import SubBtn from 'theme/components/core/blocks/SidebarMenu/SubBtn'
import SubCategory from 'theme/components/core/blocks/SidebarMenu/SubCategory'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import Breadcrumbs from 'theme/components/core/Breadcrumbs.vue'
import groupBy from 'lodash-es/groupBy'

const SearchPanelMobile = () => import(/* webpackChunkName: "vsf-search-panel-mobile" */ 'theme/components/core/blocks/SearchPanel/SearchPanelMobile.vue')

export default {
  components: {
    SubCategory,
    SubBtn,
    SearchPanelMobile,
    Breadcrumbs
  },
  mixins: [SidebarMenu],
  data () {
    return {
      myAccountLinks: [
        {
          id: 1,
          name: i18n.t('My profile'),
          url: '/my-account'
        },
        {
          id: 2,
          name: i18n.t('My shipping details'),
          url: '/my-account/shipping-details'
        },
        {
          id: 3,
          name: i18n.t('My newsletter'),
          url: '/my-account/newsletter'
        },
        {
          id: 4,
          name: i18n.t('My orders'),
          url: '/my-account/orders'
        }
      ],
      oNasLinks: [
        { id: 19, name: 'Regulamin', url: '/i/regulamin' },
        { id: 29, name: 'Polityka prywatności', url: '/i/polityka-prywatnosci' },
        { id: 39, name: 'Kontakt', url: '/i/kontakt' }
      ],
      clientServiceLinks: [
        { id: 18, name: 'Dostawa', url: '/i/dostawa' },
        { id: 28, name: 'Zwroty', url: '/i/zwroty' },
        { id: 38, name: 'Płatności', url: '/i/platnosci' }
      ],
      componentLoaded: false
    }
  },
  computed: {
    menuCategoriesById () {
      console.log('debug: ', groupBy(this.getMenuCategories, 'id'))
      return groupBy(this.getMenuCategories, 'id')
    },
    mainListStyles () {
      return this.submenu.depth ? `transform: translateX(${this.submenu.depth * 100}%)` : false
    },
    ...mapState({
      submenu: state => state.ui.submenu,
      currentUser: state => state.user.current
    }),
    ...mapGetters('category-next', ['getMenuCategories']),
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    },
    visibleCategories () {
      return this.categoriesMap[config.server.mainCategoryId]
    },
    mainCategoryMenu () {
      return config.server.megaMenuCategories
    },
    // visibleCategories () {
    //   return this.categories.filter(category => {
    //     return category.product_count > 0 || category.children_count > 0
    //   })
    // },
    getPrevName () {
      // KULTOWY SPECIFIC: only one layer of menu depth
      const prevName = this.visibleCategories.find(cat => {
        return cat.id === this.submenu.path[this.submenu.path.length - 1]
      })
      return prevName && prevName.name
    },
    isCurrentMenuShowed () {
      return !this.submenu || !this.submenu.depth
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true;
      let body = document.getElementsByTagName('body')[0]
      body.classList.add('iphone-scroll')
    })
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('iphone-scroll')
  },
  methods: {
    login () {
      if (!this.currentUser && this.isCurrentMenuShowed) {
        this.$nextTick(() => {
          this.$store.commit('ui/setAuthElem', 'login')
          this.$bus.$emit('modal-show', 'modal-signup')
          this.$router.push({ name: 'my-account' })
        })
      }
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$color-silver: color(silver);
$color-matterhorn: color(matterhorn);
$color-mine-shaft: color(mine-shaft);
$color-main: color(main);
$color-green: color(forest-green);
$color-orange: color(burnt-sienna);

.bestsellery {
  color: $color-green !important;
}

.sale {
  color: $color-orange !important;
}

// special case for iphones
.save-area-overlay {
  margin-bottom: 140px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    margin-bottom: 260px;
  }
}

.search-container {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 0 4px #00000029;
  -webkit-box-shadow: 0 0 4px #00000029;
  // margin-bottom: 5px;
}

.sidebar-menu {
  height: 100vh;
  width: 350px;
  // overflow-y: scroll;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100vh;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 100%;
  }

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 55px);
  }

  &__list {
    transition: transform $duration-main $motion-main;
  }

  ul {
    list-style-type: none;
  }

  li {
    &:hover,
    &:focus {
      background-color: $color-silver;
    }

    &.bg-cl-primary {
      &:hover,
      &:focus {
        background-color: $bg-secondary;
      }
    }

    a {
      color: #000;
    }
  }

  .subcategory-item {
    display: flex;
    width: 100%;
  }

  button {
    color: #000;

    a {
      color: #000;
    }
  }

  .close-btn {
    i {
      color: #000;
    }

    &:hover,
    &:focus {
      i {
        color: #000;
      }
    }
  }

}
</style>
